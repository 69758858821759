/* Reset some default styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Set a background color */
body {
  background-color: #f0f0f0;
  font-family: Arial, sans-serif;
}

/* Main container */
.App {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

/* Timer container */
.Timer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

/* Timer button */
.timer_button {
  font-size: 16px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s, box-shadow 0.2s;
}

.timer_button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.timer_button:hover {
  background-color: #0056b3;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

/* Clock label */
.clock_label {
  font-size: 20px;
  text-align: center;
  color: #555;
}

/* Mental math problem container */
.mm_container {
  text-align: center;
  margin-bottom: 20px;
}

/* Mental math problem */
.mm_problem {
  font-size: 28px;
  color: #0056b3;
  margin-bottom: 10px;
}

/* User answer input */
.user_answer {
  font-size: 20px;
  padding: 8px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
}

.vert_spacing {
  margin: 20 0 5 0;
  border-color: green;
}

/* Seperators */
.small_seperator,
.big_seperator {
  margin: 10px 0;
  border-top: 1px solid #ccc;
}

/* Count label */
label {
  font-size: 18px;
  display: block;
  text-align: center;
  margin-bottom: 10px;
}

/* Settings container */
.Settings {
  display: flex;
  flex-direction: column;
  text-align: center;
}

/* Settings inputs */
.Settings input {
  font-size: 16px;
  padding: 5px;
  margin: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
}

/* Button styling (assuming you have buttons in the Settings component) */
.Settings button {
  font-size: 16px;
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.Settings button:hover {
  background-color: #0056b3;
}

.left_align {
  display: flex;
  flex-direction: column;
  justify-content: left;
}

.info_label {
  font-style: oblique;
  font-size: 16px;
  color: #555;
}
